import React from 'react';
import { Link } from 'gatsby';
export default (props)=>{
  const album = props.album;
  return(
    <Link to={"/albums/"+album.slug}>
      <div className="d-bg-blue shadow-md text-center p-6 text-white rounded">
        <p className="font-bold text-xl">{album.title}</p>
        <p>{album.photos.length} Fotos</p>
      </div>
    </Link>
  )
}