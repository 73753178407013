import React from 'react';
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby';
import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';

import Multimedia from '../components/Multimedia';

export default (props) => {
  const albums = props.data.allAlbumsJson.nodes;
  return (
    <Layout>

      <PageHeader>
        <h2 className="text-6xl">Multimedia</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/multimedia" className="underline">Multimedia</Link>
        </p>
      </PageHeader>

      <Container>
        <Multimedia albums={albums} />
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query {
    allAlbumsJson {
      nodes {
        title
        slug
        photos
      }
    }
  }

`;