import React from 'react';
import Album from '../components/Album';

export default ({albums}) => {
  return(
    <div className="my-8">
      <h3 className="font-bold uppercase text-2xl mb-4">Fotos</h3>
      <div className="flex flex-col sm:flex-row mb-20 sm:mb-0 mt-4">
        {
          albums.map((album, index) => (
            <Album album={album} key={index} />
          ))
        }
      </div>
      <h3 className="font-bold uppercase text-2xl my-4 mt-16">Vídeos</h3>
      <video src={require('../videos/bacteria.mp4')} controls></video>
    </div>
  )
}